<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tpm_report.resource_person') + ' ' + $t('tpm_report.honorarium_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <v-select name="organization"
                                v-model="search.fiscal_year_id"
                                label="text"
                                :reduce="item => item.value"
                                :options= fiscalYearList
                                :placeholder="$t('globalTrans.select')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              />
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                        <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.org_id"
                              :options="orgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Category"  vid="training_category_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Title"  vid="training_title_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.batch_no"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                          </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="row">
                      <b-col sm="9">
                      </b-col>
                      <b-col sm="3" style="text-align: right;">
                        <b-button size="sm" type="submit" variant="primary" class="mt-20">
                          <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                      </b-col>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
              </b-row>
          </template>
      </iq-card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay :show="loading">
            <b-row v-if='datas.length'>
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.resource_person') + ' ' + $t('tpm_report.honorarium_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                      <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                    <!-- <export-excel
                      class="btn btn-success mr-2"
                      :data="excelData"
                      :title="headerValue"
                      worksheet="Report Sheet"
                      name="payment_report.xls">
                      {{ $t('globalTrans.export_excel') }}
                    </export-excel> -->
                  </template>
                  <template v-slot:body>
                    <b-overlay>
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive">
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  {{ $t('tpm_report.resource_person') + ' ' +  $t('tpm_report.honorarium_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                  <table style="width:100%;color:black;">
                                    <tr>
                                      <td align="left" style="width:10%">{{ $t('elearning_config.fiscal_year') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.fiscal_year_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}</b></td>
                                      <td align="left" style="width:50%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      <td align="left" style="width:12%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.circular_memo_no"><b>{{ ($i18n.locale==='bn') ? search.circular_memo_no : search.circular_memo_no }}</b></td>
                                      <td align="left" style="width:25%" v-else><b>{{ '' }}</b></td>
                                    </tr>
                                    <tr>
                                      <td align="left" style="width:10%">{{ $t('elearning_config.training_title') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.training_title_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.training_title_bn : searchHeaderData.training_title_en }}</b></td>
                                      <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      <td align="left" style="width:12%">{{ $t('elearning_iabm.batch_no') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.batch_no"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.batch_bn : searchHeaderData.batch_en }}</b></td>
                                      <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                    </tr>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <!-- <pre>{{ datas }}</pre> -->
                              <!-- <b-col md="3" class="text-left">
                                <span>{{ $t('globalTrans.fiscal_year') }}</span>:
                                <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                              </b-col>
                              <b-col md="3" class="text-left">
                                <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                                <strong>{{ search.training_title_id ? ($i18n.locale === 'en' ? searchHeaderData.training_title_en : searchHeaderData.training_title_bn) : $t('globalTrans.all') }}</strong>
                              </b-col>
                              <b-col md="3" class="text-left">
                                <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                                <strong>{{ search.circular_memo_no ? ($i18n.locale === 'en' ? search.circular_memo_no : search.circular_memo_no) : $t('globalTrans.all') }}</strong>
                              </b-col>
                              <b-col md="3" class="text-left">
                                <span>{{ $t('elearning_iabm.batch_no') }}</span>:
                                <strong>{{ search.batch_no ? ($i18n.locale === 'en' ? searchHeaderData.batch_en : searchHeaderData.batch_bn) : $t('globalTrans.all') }}</strong>
                              </b-col> -->
                            </b-row>
                            <!-- <b-row>
                              <b-col>
                                <div class="text-center">
                                  <table style="width:100%;color:black;">
                                    <tr v-if="search.date">
                                      <td align="right" style="width:45%">{{ $t('globalTrans.date') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ search.date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="search.fiscal_year">
                                      <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                    </tr>
                                    <tr v-if="search.org_name">
                                      <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                    </tr>
                                    <tr v-if="search.training_type">
                                      <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                    </tr>
                                    <tr v-if="search.training_category">
                                      <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                    </tr>
                                    <tr v-if="search.training_title">
                                      <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                    </tr>
                                  </table>
                                </div>
                              </b-col>
                            </b-row> -->
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length">
                                  <b-thead>
                                    <b-tr>
                                      <b-th class="text-center" style="width:5%">{{$t('globalTrans.sl_no')}}</b-th>
                                      <b-th style="width:12%">{{$t('globalTrans.name')}}</b-th>
                                      <b-th style="width:12%">{{$t('globalTrans.designation')}}</b-th>
                                      <b-th style="width:12%">{{$t('elearning_tpm.work_place')}}</b-th>
                                      <!-- <th style="width:12%">{{$t('globalTrans.mobile')}}</th>
                                      <th style="width:12%">{{$t('globalTrans.email')}}</th> -->
                                      <b-th style="width:7%" class="text-right">{{$t('globalTrans.number_of_days')}}</b-th>
                                      <b-th style="width:12%" class="text-right">{{$t('elearning_tim.daily_honorarium')}}</b-th>
                                      <b-th style="width:7%" class="text-right">{{$t('elearning_tim.total_honorarium')}}</b-th>
                                      <b-th style="width:7%" class="text-right">{{$t('elearning_tim.other_detuct')}}</b-th>
                                      <b-th style="width:10%" class="text-right">{{$t('elearning_tim.total_tk')}}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(data, index) in datas" :key="index">
                                      <b-td class="text-center" >
                                        {{ $n(index + 1) }}
                                      </b-td>
                                      <b-td>
                                        {{ $i18n.locale === 'bn' ? data.name_bn : data.name }}
                                      </b-td>
                                      <b-td class="text-center">
                                        <!-- <span v-if="data.p_designation_id && data.not_here_designation == 0">{{ getDesignation(data.p_designation_id) }}</span>
                                        <span v-if="data.not_here_designation == 1">{{ ($i18n.locale=='bn') ? data.p_designation_bn : data.p_designation_en}}</span> -->
                                        <span>{{ ($i18n.locale=='bn') ? data.p_designation_bn : data.p_designation_en}}</span>
                                      </b-td>
                                      <b-td class="text-center">
                                        <span v-if="data.p_profession_type == 1 && data.not_here_office == 0">{{ getOfficeName(data.p_office_id) + ', ' + getOrgName(data.p_org_id) }}</span>
                                        <span v-else-if="data.p_profession_type == 1 && data.not_here_office == 1">{{ ($i18n.locale=='bn') ? data.p_office_name_bn : data.p_office_name }}</span>
                                        <span v-else>{{ ($i18n.locale=='bn') ? data.p_office_name_bn : data.p_office_name }}</span>
                                      </b-td>
                                      <b-td class="text-right">
                                        {{ $n(data.total_days) }}
                                      </b-td>
                                      <b-td class="text-right">
                                        {{ $n(data.honour_amount) }}
                                      </b-td>
                                      <b-td class="text-right">
                                        {{$n(data.total_days * data.honour_amount)}}
                                      </b-td>
                                      <b-td class="text-right">
                                        {{ $n(data.vat_tax_amount) }}
                                      </b-td>
                                      <b-td class="text-right">
                                        {{ $n(data.payment_amount) }}
                                      </b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-center" v-if="$i18n.locale==='bn'"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                              <b-col class="text-center" v-if="$i18n.locale==='en'">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentReport, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
      ListReportHead
  },
  created () {
      this.search = Object.assign({}, this.search, {
          org_id: this.$store.state.dataFilters.orgId,
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
      }
      this.getCircularList()
      // if (this.id) {
      //     const tmp = this.getEditingData()
      //     this.search = tmp
      // }
  },
  data () {
      return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        search: {
          circular_memo_no: '',
          training_type_id: 0,
          training_category_id: 0,
          payment_type: 'Trainer',
          payment_status: 2,
          training_status: 1,
          training_title_id: 0,
          org_id: 0,
          batch_no: 0,
          fiscal_year_id: 0
        },
        searchHeaderData: {
          fiscal_year_en: '',
          fiscal_year_bn: '',
          org_en: '',
          org_bn: '',
          circular_memo_no: '',
          training_type_en: '',
          training_type_bn: '',
          training_title_en: '',
          training_title_bn: '',
          batch_en: '',
          batch_bn: ''
        },
        headerExcelDefault: {
          orgName: null,
          orgNameBn: null,
          address: null,
          address_bn: null
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainingTypeList: [],
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        datas: [],
        circularList: [],
        allBatchDataList: [],
        batchList: [],
        loading: false,
        circularLoading: false,
        showData: false
      }
  },
  mounted () {
      core.index()
      flatpickr('.fromDate', {})
      this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.headerExcelDefault.orgName
        headerVal[1] = this.headerExcelDefault.address
        headerVal[2] = this.$t('tpm_report.allowance_report')
      } else {
        headerVal[0] = this.headerExcelDefault.orgNameBn
        headerVal[1] = this.headerExcelDefault.address_bn
        headerVal[2] = this.$t('tpm_report.allowance_report')
      }
      if (this.search.date) {
        headerVal.push(
          this.$t('globalTrans.date') + ' :  ' + this.search.date
        )
      }
      if (this.search.fiscal_year_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year_bn : this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year
        )
      }
      if (this.search.org_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.organization') + ' :  ' + this.search.org_name_bn : this.$t('elearning_config.organization') + ' :  ' + this.search.org_name
        )
      }
      if (this.search.training_type_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type_bn : this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type
        )
      }
      if (this.search.training_category_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category_bn : this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category
        )
      }
      if (this.search.training_title_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title : this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title
        )
      }
      return headerVal
    },
    // excelData: function () {
    //   const listData = this.datas
    //   var serial = 0
    //   const listContractor = listData.map(keyItem => {
    //     serial++
    //       if (this.$i18n.locale === 'en') {
    //           return {
    //             sl: serial,
    //             Type: keyItem.payment_type,
    //             Name: keyItem.personal.name,
    //             'Honour Amount': keyItem.honour_amount,
    //             'Vat/Tax (%)': keyItem.vat_tax,
    //             Other: keyItem.other,
    //             Detucton: keyItem.detucton,
    //             'Payment Amount': keyItem.payment_amount
    //           }
    //       } else {
    //         return {
    //           'ক্রমিক সংখ্যা': this.$n(serial),
    //           টাইপ: keyItem.payment_type,
    //           নাম: keyItem.personal.name,
    //           'সম্মানের পরিমাণ': this.$n(keyItem.honour_amount),
    //           'মুল্য সংযোজন কর (%)': this.$n(keyItem.vat_tax),
    //           অন্যান্য: this.$n(keyItem.other),
    //           বিচ্ছিন্ন: this.$n(keyItem.detucton),
    //           'পরিশোধিত অর্থ': this.$n(keyItem.payment_amount)
    //         }
    //       }
    //   })
    //   return listContractor
    // },
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    currentFiscalYearId: function () {
      return this.$store.state.TrainingElearning.currentFiscalYearId
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    timePreiodList: function () {
      return this.$store.state.agriMarketing.commonObj.timePreiodList
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'search.circular_memo_no': function (newValue) {
    //       this.getCircularMemoNo(newValue)
    //   },
    // 'search.training_type_id': function (newValue) {
    //   this.trainingCategoryList = this.getCategoryList(newValue)
    // },
    // 'search.training_category_id': function (newValue) {
    //   this.trainingTitleList = this.getTrainingTitleList(newValue)
    // }
    'search.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    }
  },
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getBatch (id) {
      const sectorObj = this.allBatchList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getOffice (id) {
      const sectorObj = this.$store.state.commonObj.officeList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getDesignation (id) {
      const sectorObj = this.$store.state.commonObj.designationList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getOrg (id) {
      const sectorObj = this.$store.state.commonObj.organizationProfileList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.search.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        training_title_id: this.search.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
      // async getCircularList () {
      //     this.circularLoading = true
      //     this.circularLoading = true
      //     const serchData = {
      //         org_id: this.$store.state.dataFilters.orgId
      //     }
      //     const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
      //     if (!result.success) {
      //     } else {
      //         const listObject = result.data
      //         const tmpList = listObject.map((obj, index) => {
      //         if (this.$i18n.locale === 'bn') {
      //             return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         } else {
      //             return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         }
      //         })
      //         this.circularList = tmpList
      //     }
      //     this.circularLoading = false
      // },
      // async getCircularMemoNo (getCircularMemoNo) {
      //     if (getCircularMemoNo) {
      //         this.loading = true
      //         const params = {
      //           circular_memo_no: getCircularMemoNo,
      //           table: 'tpm_training_calendar'
      //         }
      //         const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      //         if (!result.success) {
      //         } else {
      //             this.circularErrorMsg = false
      //             const trData = result.data
      //             this.search.training_title_id = trData.training_title_id
      //             this.search.training_type_id = trData.training_type_id
      //             this.search.training_category_id = trData.training_category_id
      //         }
      //         this.loading = false
      //     }
      // },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    pdfExport () {
        const reportTitle = this.$t('tpm_report.resource_person') + ' ' + this.$t('tpm_report.honorarium_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
    },
    onChangeFile (e) {
        this.attachmentDemo = e.target.files[0]
    },
    getEditingData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
    },
    async searchData () {
      // RestApi.getData(trainingElearningServiceBaseUrl, '/config/report-head/detail/12').then(response => {
      //   if (response.success) {
      //     const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 3)
      //     const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
      //     const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
      //     this.headerExcelDefault.orgName = orgName
      //     this.headerExcelDefault.orgNameBn = orgNameBn
      //     this.headerExcelDefault.address = response.data.address
      //     this.headerExcelDefault.address_bn = response.data.address_bn
      //   }
      // })
      this.loading = true
      this.showData = true
      if (this.search.fiscal_year_id) {
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      }
      if (this.search.circular_memo_no) {
        const circularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
        const circularData = {
          circular_memo_no_en: circularObj?.text,
          circular_memo_no_bn: circularObj?.text
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
      }
      if (this.search.training_title_id) {
        const titleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
        const titleData = {
          training_title_en: titleObj?.text_en,
          training_title_bn: titleObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, titleData)
      }
      if (this.search.batch_no) {
        const batchObj = this.batchList.find(obj => obj.value === this.search.batch_no)
        const batchData = {
          batch_en: batchObj?.text_en,
          batch_bn: batchObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
      }
      // const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
      // this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
      // this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

      // const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
      // this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
      // this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

      // const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
      // this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
      // this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

      // const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
      // this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
      // this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

      // const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
      // this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
      // this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

      const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentReport, this.search)
      if (result.success) {
        this.loading = false
        if (result.data) {
          this.datas = this.getCustomDataList(result.data)
        }
      } else {
        this.datas = []
        this.loading = false
      }
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }

        const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
        const officeData = {}
        if (typeof officeObj !== 'undefined') {
          officeData.office = officeObj.text_en
          officeData.office_bn = officeObj.text_bn
        } else {
          officeData.office = ''
          officeData.office_bn = ''
        }

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        const OfficeTypeData = {}
        if (typeof OfficeTypeListObj !== 'undefined') {
          OfficeTypeData.office_type = OfficeTypeListObj.text_en
          OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          OfficeTypeData.office_type = ''
          OfficeTypeData.office_type_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        const designationObj = this.$store.state.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
        const designationData = {}
        if (typeof designationObj !== 'undefined') {
          designationData.designation_name = designationObj.text_en
          designationData.designation_name_bn = designationObj.text_bn
        } else {
          designationData.designation_name = ''
          designationData.designation_name_bn = ''
        }
        const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
        const desigData = {}
        if (typeof desigObj !== 'undefined') {
          desigData.designation_name = desigObj.text_en
          desigData.designation_name_bn = desigObj.text_bn
        } else {
          desigData.designation_name = ''
          desigData.designation_name_bn = ''
        }
        return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, designationData, desigData)
      })
      return listData
    }
  }
}
</script>
